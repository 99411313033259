.singleManuPage {
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 1170px;
  margin: 30px auto;
  width: 100%;
}

.singleManuBlock {
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.25);
  padding: 35px 40px;
  border-radius: 8px;
  gap: 20px;
}

.singleManuProducts {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.singleManuFilter > select {
  width: 250px;
  border-radius: 3px;
  border: 2px solid #f5f5f5;
  padding: 8px;
  outline: none;
}

.singleManuDetails {
  display: flex;
  justify-content: space-between;
}

.singleManuDetailsLeft {
  display: flex;
  align-items: center;
  gap: 20px;
}

.singleManuDetailsLeft > img {
  width: 120px;
  height: 120px;
  border-radius: 10px;
  cursor: pointer;
}

.singleManuDetailsLeftRight {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.singleManuDetailsLeftRight > h1 {
  color: #333;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.singleManuDetailsLeftRight > span {
  color: #a6a6a6;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.singleManuDeailsIcons {
  display: flex;
  align-items: center;
  gap: 15px;
}

.singleManuDetailsRight {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 15px;
}

.singleManuDetailsRightCheckbox {
  display: flex;
  gap: 5px;
}

.singleManuDetailsRightCheckbox > span {
  color: #333;
  text-align: right;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.eachSingleManuDetailsRightIcon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  border-radius: 8px;
  background: #f2f2f2;
  width: 92px;
  height: 67px;
  cursor: pointer;
}

.eachSingleManuDetailsRightIcon > span {
  color: #333;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.singleManuDetailsRightIcons {
  display: flex;
  align-items: center;
  gap: 10px;
}
