.myProfilePage {
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 1170px;
  margin: 30px auto;
  width: 100%;
}

.profileBlock {
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.25);
  padding: 35px 40px;
  border-radius: 8px;
}

.cursor {
  cursor: pointer;
}

.profileButton {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.profileButton > button {
  width: 260px;
  padding: 14px;
  border-radius: 13px;
  border: none;
  background: var(--2-d-9-efb, #2d9efb);
  color: #fff;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}

@media screen and (max-width: 1240px) {
  .myProfilePage {
    padding: 0 20px;
  }
}
