.allManufacturersPage {
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 1170px;
  margin: 30px auto;
  width: 100%;
}

.allManufacturers {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  align-self: center;
}

.notFound {
  color: #333;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media screen and (max-width: 1240px) {
  .allManufacturersPage {
    padding: 0 20px;
  }
}
