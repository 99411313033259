.timer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.timer > span {
  color: #333;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: underline;
}

.timer > p {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
