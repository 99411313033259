.activePopup {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.25) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    0px 8px 18px rgba(24, 39, 75, 0.12);
  fill: rgba(255, 255, 255, 0.25);
  filter: drop-shadow(0px 8px 18px rgba(24, 39, 75, 0.12))
    drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  backdrop-filter: blur(20px);
  position: fixed;
  left: 0;
  top: 0;
  display: grid;
  place-items: center;
  z-index: 4;
}

.inactive {
  display: none;
}

.pop {
  max-width: 670px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    0px 8px 18px rgba(24, 39, 75, 0.12);
  padding: 30px;
  background: #fff;
  border-radius: 15px;
  display: grid;
  justify-items: center;
  align-items: center;
  margin: 20px;
  position: relative;
  max-height: calc(100vh - 40px);
  gap: 20px;
  overflow-y: auto;
}

.close {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.eachSingleProdDetails {
  width: 100%;
}

.carouselImages {
  width: 100%;
  max-width: 700px;
  height: 100%;
  max-height: calc(100vh - 100px);
  object-fit: fill;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  height: 50px !important;
  top: 43% !important;
  border-radius: 3px !important;
}

.carousel.carousel-slider .control-arrow:hover {
  background: rgb(255, 255, 255) !important;
}

.control-next.control-arrow:before {
  border-left: 8px solid #2d9efb !important;
}

.control-prev.control-arrow:before {
  border-right: 8px solid #2d9efb !important;
}

.carousel-root,
.carousel.carousel-slider,
.carousel .slider-wrapper,
.slider,
.slide {
  height: 100% !important;
}
