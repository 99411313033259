.myProductsPage {
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 1170px;
  margin: 30px auto;
  width: 100%;
}

.myProductsBlock {
  background: #fff;
  box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.25);
  padding: 35px 40px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.myProductCategories {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 15px;
}

.eachProductCategory {
  padding: 9px 16px 10px 16px;
  border-radius: 8px;
  background: #f5f5f5;
  border: none;
  color: #333;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
}

.myProductsButton {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.myProductsButton > button {
  width: 260px;
  padding: 14px;
  border-radius: 13px;
  background: var(--2-d-9-efb, #2d9efb);
  border: none;
  color: #fff;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}

.myProducts {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  flex-wrap: wrap;
}

.eachProduct {
  width: 48%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.eachProduct > img {
  width: 100%;
  height: 360px;
  margin-bottom: 10px;
}

.eachProduct > h2 {
  color: #333;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.eachProduct > span {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.eachProductButtons {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-top: 20px;
}

.eachProductButtons > button {
  padding: 10px;
  width: 260px;
  border-radius: 8px;
  border: 2px solid #888;
  color: #888;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}

.about {
  display: flex;
  align-items: center;
  gap: 5px;
}

@media screen and (max-width: 1240px) {
  .myProductsBlock {
    padding: 20px;
    margin: 20px;
  }
  .eachProduct {
    width: 320px;
  }
  .eachProduct > img {
    height: 260px;
  }
}
