.newProductPage {
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 1170px;
  margin: 30px auto;
  width: 100%;
}

.newProductBlock {
  background: #fff;
  box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.25);
  padding: 35px 40px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.newProductPhotoBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.newProductPhotoBlock > label {
  color: #333;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.newProductPhotoBlock > button {
  border-radius: 5px;
  background: var(--2-d-9-efb, #2d9efb);
  width: 110px;
  padding: 10px;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  cursor: pointer;
  position: relative;
}

.addProductButton > button {
  width: 260px;
  padding: 15px;
  border-radius: 13px;
  background: var(--2-d-9-efb, #2d9efb);
  color: #fff;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: none;
  cursor: pointer;
}

.addProductPrice {
  position: relative;
  display: flex;
  align-items: center;
}

.addProductPrice > svg {
  position: absolute;
  right: 10px;
}

.addProductPrice > input {
  width: 100%;
  border: 1px solid #bebebe;
  border-radius: 8px;
  outline: none;
  padding: 16.5px;
  padding-right: 40px;
  font-weight: 500;
  font-size: 15px;
}

.eachProductPhoto {
  position: relative;
  width: 200px;
  height: 200px;
}

.eachProductPhoto > img {
  width: 200px;
  height: 200px;
  object-position: center;
  border-radius: 8px;
}

.deletePhoto {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.newProductPhotos {
  display: flex;
  align-items: center;
  gap: 20px;
  overflow-x: auto;
  padding-bottom: 20px;
}

input[type="file"] {
  opacity: 0;
  position: absolute;
  left: 0;
  width: 110px;
  height: 36px;
  top: 0;
  cursor: pointer;
}
