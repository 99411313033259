.eachManufacturer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.25);
}

.eachManuTop {
  display: flex;
  align-items: center;
  gap: 20px;
}

.eachManuTop > img {
  width: 100px;
  height: 100px;
  border-radius: 4px;
  cursor: pointer;
}

.eachManuTopTitle > h2 {
  color: #333;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  word-break: break-all;
}

.eachManuTopTitle > span {
  color: #a6a6a6;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.eachManuCats {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 15px;
}

.eachManuCategory {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #f5f5f5;
  padding: 10px 15px;
  color: #333;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
